import { createApp } from 'vue'
import init from './init'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './index.css'
import ActivityBasedTimer from './utils/activityBasedTimer'
import timeago from 'vue-timeago3'
import { de } from 'date-fns/locale'
import Vue3Toasity from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

init()

if (process.env.VUE_APP_FEATURE_TOGGLES) {
  window.FEATURE_TOGGLES = {
    ...window.FEATURE_TOGGLES,
    ...process.env.VUE_APP_FEATURE_TOGGLES.split(',').reduce((o, x) => {
      o[x] = true
      return o
    }, {})
  }
}

;(() => {
  let ft = localStorage.getItem('featureToggles')

  if (ft) {
    ft = JSON.parse(ft)
    window.FEATURE_TOGGLES = {
      ...window.FEATURE_TOGGLES,
      ...ft
    }
  }
})()

if (process.env.VUE_APP_API) {
  window.API = process.env.VUE_APP_API
}

window.versioningTimer = ActivityBasedTimer()
;(async() => {
  try {
    const { data: version } = await axios.get(`/version.json?${new Date() - 0}`)

    if (version.version === 'dd245f3e7ade890a4ba463fa939ac2706129748d') {
      return
    }

    window.location.reload(true)
  } catch (ex) {}
})()

window.versioningTimer.setInterval({
  async callback() {
    try {
      const { data: version } = await axios.get(
        `/version.json?${new Date() - 0}`
      )

      if (version.version === 'dd245f3e7ade890a4ba463fa939ac2706129748d') {
        return
      }

      if (
        window.confirm(
          'An update is available, this needs to reload your browser. Please click ok to reload now.'
        )
      ) {
        window.location.reload(true)
      }
    } catch (ex) {}
  },
  // Normal interval is once every 10 minutes
  interval: 1000 * 60 * 2,
  // Forced interval is once per day
  forcedInterval: 1000 * 60 * 60
})

/** @type {import('vue3-toastify/index').ToastContainerOptions} */
const toastConfig = {
  autoClose: 3000,
  multiple: true,
  limit: 5,
  newestOnTop: true
}

const app = createApp(App)
app.config.globalProperties.$user = store.state.users.authUser
app
  .use(store)
  .use(router)
  .use(Vue3Toasity, toastConfig)
  .use(VueAxios, axios)
  .use(timeago, { converterOptions: { includeSeconds: false }, locale: de })
  .mount('#app')
